<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Thông tin phiếu chuyển kho sang hệ thống cũ'"
        >
          <template v-slot:preview>
            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-3"
              >
                <template>
                  <span>Ngày chuyển kho:</span>
                  <span class="text-danger">*</span>
                </template>
                <date-picker
                  placeholder="Chọn ngày chuyển kho"
                  class="mb-2 mt-2 form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateImport"
                ></date-picker>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-3"
              >
                <template>
                  <span>Tổng tiền:</span>
                </template>
                <p class="mt-2">{{ formatMoney(totalPrice) }}</p>
              </b-form-group>
            </div>

            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="col-3"
              >
                <template>
                  <span>Kho nguồn:</span>
                  <span class="text-danger">*</span>
                </template>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptions"
                  @selected="onSelectedSourceStore"
                  :limit="200"
                  @input="onInputChangeSourceStore"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn kho nguồn',
                    style: 'border-radius:0px!important',
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchSourceStore"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="col-3"
              >
                <template>
                  <span>Kho đích:</span>
                  <span class="text-danger">*</span>
                </template>
                <vue-autosuggest
                  class="border-radius-none mt-2"
                  aria-describedby="input-store-live-feedback"
                  :suggestions="filteredOptionsDesStore"
                  @selected="onSelectedDesStore"
                  :limit="200"
                  @input="onInputChangeDesStore"
                  :input-props="{
                    id: 'autosuggest__input',
                    placeholder: 'Chọn kho đích',
                    style: 'border-radius:0px!important',
                  }"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchDesStore"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.suggestionName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-form-group>
            </div>
            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group
                id="input-group-1"
                label-for="input-1"
                class="col-6"
              >
                <template>
                  <span>Nội dung:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  id="textarea1"
                  v-model="notes"
                  :placeholder="'Thêm nội dung...'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <b-row class="mb-4 col-md-12" style="padding-left: 0px">
              <b-col class="col-md-2">
                <b-form-select
                  id="input-1"
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                ></b-form-select>
              </b-col>
              <b-col class="col-md-4">
                <vue-autosuggest
                  v-model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  @selected="onSelectedProduct"
                  :limit="10"
                  @input="onInputChangProduct"
                  :input-props="inputSearchProductProp"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProduct !== ''
                  "
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <div style="{ display: 'flex', color: 'navyblue'}">
                      {{ suggestion.item.productName }}
                    </div>
                  </div>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <span class="font-weight-bolder">Danh sách sản phẩm:</span>
            <table
              class="table table-bordered table-vertical-center table-hover col-12 mt-4"
            >
              <thead>
                <tr>
                  <th scope="col" class="title-center">Tên sản phẩm</th>
                  <th scope="col" class="title-center" style="width: 5%">
                    Tồn
                  </th>
                  <th scope="col" class="title-center">IMEI</th>
                  <th
                    scope="col"
                    class="title-center"
                    v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                  >
                    Đơn giá
                  </th>
                  <th scope="col" class="title-center">Số lượng</th>
                  <th
                    scope="col"
                    class="title-center"
                    v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                  >
                    Thành tiền
                  </th>
                  <th scope="col" class="title-center"></th>
                </tr>
              </thead>

              <tbody v-for="item in listProductStock" :key="item.id">
                <TransferStock
                  v-bind:productItem="item"
                  v-on:cancelAdd="cancelAdd"
                  v-on:save="save"
                  v-on:deleteItem="deleteItemInArray"
                  v-on:update="updateItem"
                  v-bind:editState="true"
                  v-bind:canViewOriginalPrice="
                    checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
                  "
                />
              </tbody>
              <tr>
                <td
                  :colspan="
                    checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW') ? 4 : 3
                  "
                  class="tdTextAlignPrice"
                >
                  Tổng
                </td>
                <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
                <td
                  class="tdTextAlignPrice"
                  v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                >
                  {{ formatMoney(totalPrice) }}
                </td>
                <td></td>
              </tr>
            </table>

            <div class="mb-5 mt-5"></div>
          </template>
          <template v-slot:foot>
            <button
              v-if="listProductStock.length > 0"
              v-bind:style="btnCreate"
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm button"
              @click.once="validate"
            >
              Lưu
            </button>
            <router-link
              to="/transfer-stock-to-nhanh"
              tag="button"
              v-if="listProductStock.length > 0"
            >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <router-link
              to="/transfer-stock-to-nhanh"
              tag="button"
              v-if="listProductStock.length === 0"
            >
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Trở về</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import TransferStock from '../../components/TransferStock';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import { unMaskPrice } from './../../../utils/common';
import decounce from 'debounce';
import { TIME_TRIGGER, LIST_STORE_IGNORE } from './../../../utils/constants';
import localData from '../../../utils/saveDataToLocal';

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        width: '70px',
      },
      errors: [],
      codeStockBill: '',
      dateImport: '',
      dateCreate: '',
      listInventories: [],
      sourceStoreSelected: null,
      listProviders: [],
      desStoreSelected: null,
      totalPrice: 0,
      listProduct: [],
      count: 0,
      notes: '',
      listType: [
        { id: 1, name: 'Xuất trả hàng nhà cung cấp' },
        { id: 2, name: 'Xuất kho bán hàng' },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm theo IMEI',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      filteredOptionsDesStore: [],
      optionsDesStore: [
        {
          data: [],
        },
      ],
      searchSourceStore: '',
      searchDesStore: '',
      timer: null,
      totalQuantity: 0,
      isSearching: false,
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
    };
  },
  components: {
    KTCodePreview,
    TransferStock,
    datePicker,
  },
  created() {
    this.fetchStoreByUser();
    this.fetchStore();
    this.fetchProvider();
  },
  mounted() {
    this.codeStockBill = 'NK-' + moment().format('YYYYMMDD.HHmmss');
    this.dateCreate = moment().format('HH:mm DD/MM/YYYY');
    this.dateImport = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Phiếu chuyển kho sang hệ thống cũ',
        route: '/transfer-stock-to-nhanh',
      },
      { title: 'Tạo phiếu chuyển kho sang hệ thống cũ' },
    ]);
  },
  methods: {
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let imeiCode = '';
      let quantity = 0;
      this.searchProduct = option.item.productName;
      //check imei exist in list product
      if (this.selectTypeSearch === 2) {
        imeiCode = option.item.imeiCode;
        quantity = 1;
        if (!this.isExistImeiCode(imeiCode, this.listProductStock)) {
          this.count++;
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: this.selectedClassProduct.originalPrice * quantity,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
          this.checkTotalMoney();
        } else {
          this.makeToastFaile('Mã IMEI đã được thêm vào danh sách!');
        }
      } else {
        this.count++;
        if (this.selectedClassProduct.productType === 1) {
          quantity = 1;
          let index = this.listProductStock.findIndex(
            (item) => item.proId === this.selectedClassProduct.id
          );
          if (index === -1) {
            let pro = {
              id: this.count,
              name: this.selectedClassProduct.productName,
              productCode: this.selectedClassProduct.productCode,
              barCode: this.selectedClassProduct.barCode,
              totalQuantityInStock:
                this.selectedClassProduct.totalQuantityInStock != null
                  ? this.selectedClassProduct.totalQuantityInStock
                  : 0,
              IMEI: imeiCode,
              proId: this.selectedClassProduct.id,
              price: this.selectedClassProduct.originalPrice,
              quantity: quantity,
              totalPrice: this.selectedClassProduct.originalPrice * quantity,
              productType: this.selectedClassProduct.productType,
            };
            this.listProductStock.unshift(pro);
          } else {
            this.listProductStock[index].quantity =
              this.listProductStock[index].quantity + quantity;
          }
          this.checkTotalMoney();
        } else if (this.selectedClassProduct.productType === 2) {
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: this.selectedClassProduct.originalPrice * quantity,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
        }
      }
      this.searchProduct = '';
      this.checkTotalMoney();
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    createStock: function () {
      let listPro = [];
      this.listProductStock.forEach((element) => {
        let item = {
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
        };
        listPro.push(item);
      });
      let data = {
        code: this.codeStockBill,
        id: this.$uuid.v4(),
        sourceStoreId: this.sourceStoreSelected,
        desStoreId: this.desStoreSelected,
        description: this.notes,
        transferDate: this.dateImport
          ? moment(this.dateImport, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        listProduct: listPro,
      };
      //alert(`${JSON.stringify(data)}`);
      ApiService.setHeader();
      ApiService.post('transferToNhanh/create-transfer-stock', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                name: 'update-transfer-stock-to-nhanh',
                query: { id: data.data },
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    cancelAdd() {
      this.listProduct.shift();
    },
    save(item) {
      this.listProduct[0] = item;
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    checkTotalMoney() {
      this.totalPrice = 0;
      this.totalQuantity = 0;
      this.listProductStock.forEach((element) => {
        let total = element.quantity * unMaskPrice(element.price);
        this.totalPrice += total;
        this.totalQuantity += parseInt(element.quantity);
      });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
        this.listInventories.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
          let checkStore = LIST_STORE_IGNORE.find(
            (storeId) => storeId === element.id
          );
          if (!checkStore) {
            this.optionsDesStore[0].data.push(store);
          }
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
        let tmpDes = [{ data: this.optionsDesStore[0].data }];
        this.filteredOptionsDesStore = tmpDes;
      });
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.listProductSearch = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store?storeId=${this.sourceStoreSelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    updateItem(item) {
      this.listProduct.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProduct[index].name = item.name;
          this.listProduct[index].IMEI = item.IMEI;
          this.listProduct[index].proId = item.proId;
          this.listProduct[index].price = item.price;
          this.listProduct[index].quantity = parseInt(item.quantity);
          this.listProduct[index].totalPrice = item.totalPrice;
        }
      });
      this.totalPrice = 0;
      this.checkTotalMoney();
    },
    validate() {
      this.errors = [];
      if (!this.dateImport) {
        this.errors.push('Thiếu ngày chuyển kho');
      }
      if (this.sourceStoreSelected == null) {
        this.errors.push('Thiếu kho nguồn');
      }
      if (this.desStoreSelected == null) {
        this.errors.push('Thiếu kho đích');
      }
      if (this.listProduct.length == 0) {
        this.errors.push('Đang không có sản phẩm nào để nhập kho');
      }

      if (this.errors.length == 0) {
        this.createStock();
      } else {
        this.errors.forEach((element) => {
          this.makeToastFaile(element);
        });
      }
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      ApiService.setHeader();
      ApiService.get(
        `stocks/get-product-by-store-imei?storeId=${this.sourceStoreSelected}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    isExistImeiCode(imeiCode, listProduct) {
      let check = false;
      for (let index = 0; index < listProduct.length; index++) {
        const element = listProduct[index];
        let listImei = element.IMEI.replace(/[\n\r]/g, ', ').split(',');
        for (let i = 0; i < listImei.length; i++) {
          const ele = listImei[i].trim();
          if (ele === imeiCode) {
            check = true;
            break;
          }
        }
        if (check) {
          break;
        }
      }
      return check;
    },
    onSelectedSourceStore(option) {
      this.searchSourceStore = option.item.name;
      this.sourceStoreSelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChangeSourceStore(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchSourceStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.onChangeStore();
    },
    onSelectedDesStore(option) {
      this.searchDesStore = option.item.name;
      this.desStoreSelected = option.item.id;
      this.onChangeStore();
    },
    onInputChangeDesStore(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchDesStore = text;
      const filteredData = this.optionsDesStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsDesStore = [
        {
          data: filteredData,
        },
      ];
    },
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        } else {
          this.fetchProduct(textSearch);
        }
      }
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.searchSourceStore = data.data.name;
          this.inputSearchProductProp.disabled = false;
          this.sourceStoreSelected = data.data.id;
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
